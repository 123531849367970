<template>
<div class="tabulation">
  <el-card class="chart-card">
    <div style="margin-top: 20px;">
      <el-form
          ref="ruleFormRef"
          :model="form"
          :rules="rules"
          class="demo-ruleForm"
          :size="formSize"
          status-icon
       >
      <!-- 标题 -->
        <el-form-item label="标题名称" prop="name">
          <div class="aaaa">
            <el-input v-model="form.name"  placeholder="请填写标题" size="large"></el-input>
          </div>
        </el-form-item>
      <!-- 标题图片 -->
        <el-form-item  label="主题图片" prop="breviary">
          <el-upload action="/admin/child/saveImage" list-type="picture-card" limit="2"
            ref="qingkong" 
            v-model="form.breviary"
            :auto-upload="true" 
            :on-change="change" 
            :on-remove="Remove"
            :class="styA==0?'styB':'styC'"
            :before-upload="beforeImageUpload"
            :before-remove="beforeremove"
            :on-preview="onpreview"
            :headers="header"
            accept=".jpeg,.png,.jpg,.bmp,.gif"
            :data='{
              imageFile: file
              }'
            name="imageFile"
            :on-success="succ"
            >
           <el-icon><Plus /></el-icon>
          </el-upload>

          <el-dialog v-model="dialogVisible">
            <img w-full :src="dialogImageUrl" alt="Preview Image" style="width:100%"/>
          </el-dialog>
          <div class="referrals">
            ( 推荐：图片尺寸 宽700px、高280px。 )
          </div>
        </el-form-item>
        <!-- 请选择组织 -->
        <el-form-item label="所属工会" prop="choose">
          <div class="aaaa">
            <el-input v-model="form.choose" readonly   placeholder="请选择所属工会" @click="visible = true"></el-input>
          </div>
        </el-form-item>
      <!-- 是否发布 -->
       <el-form-item label="是否发布" prop="are">
          <el-select v-model="form.are" placeholder="请选择是否发布" size="medium">
            <el-option label="是" value="1"  style="width:100%; text-align-last: center;" ></el-option>
            <el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
          </el-select>
        </el-form-item>
      <!-- 编辑器 -->
      <el-form-item prop="copyreader">
        <div class="subject">
          <span class="xingxing">*</span>
          主体内容
          <span class="referrals">( 推荐：视频体积小于40M，图片体积小于1M。 )</span>
        </div>
        <div style="border: 1px solid #ccc; width:100%">
          <Toolbar
            style="border-bottom: 1px solid #ccc"
            :editor="editorRef"
            :defaultConfig="toolbarConfig"
            :mode="mode"
          />
      
          <Editor
            style="height: 500px; overflow-y: hidden"
            v-model="valueHtml"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="handleCreated"
          />
        </div>
      </el-form-item>
    </el-form>
    </div>
  <el-button type="primary" size="large" style="margin-top: 20px;" :loading="ConfirmLoading" @click="onSubmit">提交</el-button>
  </el-card>
  <div>
    <s3-layer v-model="visible" title="所属组织" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
      <el-tree
        :data="texture"
        :props="defaultProps"
        accordion
        @node-click="dendrogram"
      />
    </s3-layer>
  </div>
</div>
</template>
<script>
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { onBeforeUnmount, ref, shallowRef, onMounted, reactive } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import {  Plus,} from '@element-plus/icons-vue'
import * as imageConversion from 'image-conversion'
import { ElMessage } from "element-plus";
import { ZNpresent,ZClist } from "../../utils/api"
import { useRouter } from "vue-router";
// import { Pictures } from '../../utils/api'
import qs from 'qs'
export default{
  name:"childrenadd",
  components:{  Editor, Toolbar, Plus, },
  setup(){
    // 选择所属工会
    let texture =ref([])
    const yes = () => {
      form.choose=options.value
      visible.value = false; 
    };
    const defaultProps = {
      children: 'unionDtoList',
      label: 'unionName',
    }
    // 获取工会code
    let gaincode=ref('')
    // 树形选择
    let options=ref('')
    let dendrogram = (node)=>{
      options.value=node.unionName
      form.choose=options.value
      gaincode.value=node.unionCode
    }
    // 获取组织列表信息
    const messge=()=>{
      ZClist().then((res)=>{
        console.log(res)
        texture.value=res.data.data.manageUnionList
      })
    }
    const visible = ref(false);




    const qingkong=ref('')
    let data = ref(null);
    // 按钮加载
    const ConfirmLoading=ref(false)
    
    // 上传图片
    const header=ref({
      timeout: 10000,
      'accessToken':sessionStorage.getItem('token')
    })
    // 获取上传图片成功地址
    let location= ref('')
    const succ=(res)=>{
      location.value=res.data
      console.log(res)
    }
    // 自定义上传
    const httpreqst=(param)=>{
      console.log(param)
      console.log(param.file.name)
      data.value ={
        imageFile:param.file.namefileList
      }
      // Pictures(data).then((res)=>{
      //   console.log(res)
      // })
    }
    const styA = ref(0)
    
    const change=(res,fileList)=>{
      // console.log(res)
      styA.value=fileList.length
      form.breviary=fileList
      // console.log(fileList)
    }
    const beforeImageUpload=(File)=>{
      console.log(File)
      let isLt2M = File.size / 1024 / 1024 < 1 
      return new Promise((resolve)=>{
        if (isLt2M) {
          resolve(File)
        }
        imageConversion.compressAccurately(File, 930).then((res) => {
          resolve(res);
          // data.value = File.name
        });
      })
    }
    // 图片放大
    const dialogVisible=ref(false)
    let dialogImageUrl=ref('')
    const onpreview = (File)=>{
      dialogVisible.value=true
      dialogImageUrl.value=File.url
      console.log(File)
    }
    // 删除图片
    const beforeremove = (File,fileList)=>{
      console.log(File)
      console.log(fileList)
      styA.value=0
    }
    const Remove =(file)=>{
      console.log(file)
    }

    // 提交 按钮
    const router = useRouter();
    const onSubmit =()=>{
      
      if(valueHtml.value=='<p><br></p>'){
        form.copyreader=''
      }else{
        form.copyreader=valueHtml.value
      }
      // console.log('标题',form.name)
      // console.log('副标题',form.bywork)
      // console.log('缩略图地址',location.value)
      // console.log('类型',form.sex)
      // console.log('是否发布',gaincode.value)
      // console.log('上传附件',address.value)
      // console.log('编辑器内容',form.copyreader)
      ruleFormRef.value.validate((valid)=>{
        console.log(valid)
        let data ={
          // 标题
          title:form.name,
          unionCode:gaincode.value,
          // 副标题
          // subtitle:form.bywork,
          // 类型
          // type:form.sex,
          // 图片
          imageInfo:location.value,
          // 是否公开
          isEnable:form.are,
          // 编辑器内容
          content:form.copyreader,
          // 附件
          // fileName:address.value,
          // 附件名称
          // fileOriginalName:files.value
        }
        if(valid==true){
          ConfirmLoading.value=true
          ZNpresent(qs.parse(data)).then((res)=>{
            console.log(res)
            if(res.data.code==0){
              ElMessage({
                message: '提交成功',
                type: "success",
                center: true,
              });
              setTimeout(()=>{
                  form.name=''
                  form.bywork=''
                  location.value=''
                  form.are=''
                  form.copyreader=''
                  valueHtml.value=''
                  styA.value=0
                  qingkong.value.clearFiles()
                  router.push({ name: 'children', params: { userId: 1 }});
                  ConfirmLoading.value=false
                },500)
            }
          })
        }else{
          ElMessage({
            message: "提交失败",
            type: "error",
            center: true,
          });
          ConfirmLoading.value=false
        }
        console.log(form)
      })
    }
 // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef()
 
    // 内容 HTML
    const valueHtml = ref('')
 
    // 模拟 ajax 异步获取内容
    onMounted(() => {
      messge()
    })
 
    const toolbarConfig = {
      toolbarKeys: [
        'bold', // 加粗
        'italic', // 斜体
        'through', // 删除线
        'underline', // 下划线
        'bulletedList', // 无序列表
        'numberedList', // 有序列表
        'color', // 文字颜色
        'fontSize', // 字体大小
        'lineHeight', // 行高
        'uploadImage', // 上传图片
        'delIndent', // 缩进
        'indent', // 增进
        'deleteImage',//删除图片
        'justifyCenter', // 居中对齐
        'justifyJustify', // 两端对齐
        'justifyLeft', // 左对齐
        'justifyRight', // 右对齐
        'undo', // 撤销
        'redo', // 重做
        'clearStyle', // 清除格式
        'uploadVideo',
      ],
      excludeKeys: [
        'insertImage', // 网络图片
        'bgColor', // 背景色
        'blockquote', // 引用
        'codeBlock', // 代码段
        'emotion', // 表情
        'fontFamily', // 字体
        'headerSelect', // 标题 
        'fullScreen', // 全屏
      ],
    }
    // 富文本上传图片地址
    // let fils=ref('')
    // placeholder: '请输入内容...',
    const editorConfig = { MENU_CONF: {} }
      // 视频上传
      editorConfig.MENU_CONF['uploadVideo'] = {
      // 上传视频的配置
      // 接口
      server:'/admin/child/saveEditorImage',
      // 请求头
      headers: {
        timeout: 10000,
        'accessToken':sessionStorage.getItem('token')
      },
      // 限制视频上传大小
      maxFileSize: 41943040, // 40M
      //上传字段 
      fieldName: 'imageFile',
      maxNumberOfFiles: 1,
      // 上传前回调
      onBeforeUpload(file) {
        console.log(file)
        let fileObj = Object.values(file)[0].data
        console.log(fileObj)
        // 判断视频是否大于40M
        let isLt2M = Object.values(file)[0].data.size
        if(isLt2M<41943040){
          return true
        }else if(isLt2M>41943040){
          ElMessage({
            message: '视频大小不能超过40MB,请重新上传',
            type: "warning",
            center: true,
          });
          return false
        }
      },
      // 上传成功后回调
      onSuccess(file, res) {
        console.log(file)
        console.log(res)
      },
      // 上传失败回调
      onError(file, err, res) {
        console.log(file) 
        console.log(err)
        console.log(res)
        if(file.size>41943040){
          ElMessage({
            message: '视频大小不能超过40MB,请重新上传',
            type: "warning",
            center: true,
          });
        }else{
          ElMessage({
            message: '上传失败，请稍后再试',
            type: "warning",
            center: true,
          });
        }
      },
    }


    // 图片上传
    editorConfig.MENU_CONF['uploadImage'] = {
    // 上传图片的配置 
    // 接口
      server:'/admin/child/saveEditorImage',
    // 请求头
      headers: {
        timeout: 10000,
        'accessToken':sessionStorage.getItem('token')
      },
      maxFileSize: 1 * 1024 * 1024, // 1M
    //上传字段 
      fieldName: 'imageFile',
      // meta: {
      //   imageFile:fils
      // },
      // 最多可上传几个文件，默认为 100
      maxNumberOfFiles: 1,
      // 上传前回调
      onBeforeUpload(file) {
        console.log(file)
        // 获取图片大小
        let fileObj = Object.values(file)[0].data
        console.log(fileObj)
        // 压缩图片
        let isLt2M = Object.values(file)[0].data.size
        if(isLt2M<1048576){
          return true
        }else if(isLt2M>1048576){
          ElMessage({
            message: '图片大小不能超过1MB,请重新上传',
            type: "warning",
            center: true,
          });
          return false
        }
      },
      // 上传成功后回调
      onSuccess(file, res) {
        console.log(file)
        console.log(res)
      },
      // 上传失败回调
      onError() {
        ElMessage({
            message: '图片大小不能超过1MB,请重新上传',
            type: "warning",
            center: true,
          });
      },
    }
 
    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
      const editor = editorRef.value 
      if (editor == null) return
      editor.destroy()
    })
 
    const handleCreated = (editor) => {
      editorRef.value = editor // 记录 editor 实例，重要！
      // console.log(editor)
    }
    // 标题验证
    const ruleFormRef = ref(null)
    const form = reactive({
      name:"",
      sex:"",
      breviary:"",
      copyreader:"",
      are:'',
      bywork:'',
      choose:'',
      breviary:''
    })
    // 验证表单提示
    const rules ={
      breviary:[
        { required: true, message: "请上传主题图片", trigger: "change" },
      ],
      choose:[
        { required: true, message: "请选择所属工会", trigger: "change" },
      ],
      are:[
        { required: true, message: "请选择是否发布", trigger: "change" },
      ],
      copyreader:[
        { required: true, message: "请填写内容", trigger: "change" },
      ],
      name:[
        { required: true, message: "请填写标题", trigger: "blur" },
      ],
      sex:[
        { required: true, message: "请选择分类", trigger: "change" },
      ],
    }
    return{
      // 选择工会弹窗
      yes,
      texture,
      defaultProps,
      dendrogram,


      visible,
      qingkong,
      header,
      succ,
      data,
      // 按钮加载
      ConfirmLoading,
      // 上传图片
      httpreqst,
      dialogVisible,
      dialogImageUrl,
      onpreview,
      beforeremove,
      Remove,
      styA,
      change,
      beforeImageUpload,
      // handlePictureCardPreview,
      // 编辑器
      onSubmit,
      editorRef,
      valueHtml,
      mode: 'default',
      toolbarConfig,
      editorConfig,
      handleCreated,
      // 标题验证
      ruleFormRef,
      form,
      rules
    }
  }
 }
</script>
<style lang='scss' scoped>
::v-deep .chart-card::-webkit-scrollbar{
  display:none
}
::v-deep .chart-card{
  height: calc(100vh - 145px);
  overflow: auto;
}
::v-deep .subject{
  margin-left: 3px;
  font-size: var(--el-form-label-font-size);
  font-weight: bold;
  margin-bottom: 5px;
  color: var(--el-text-color-regular);
  .xingxing{
    color: var(--el-color-danger);
  }
}
// .w-e-text-container [data-slate-editor] p{
//   margin-top: 22px;
//   margin-bottom: 22px;
// }
::v-deep .el-button{
  width: 100px;
  height: 40px;
  font-size: 16px;
}
::v-deep .el-form-item__content{
  align-items: flex-end;
}
::v-deep .referrals{
  color: #cccccc;
  margin-left: 5px;
  font-weight: 500;
}
::v-deep .styB .el-upload--picture-card{
  width:110px;
  height:110px;
  line-height:110px;
}
::v-deep .styC .el-upload--picture-card{
  display:none;   
}
::v-deep .el-form-item{
  align-items: center;
}
::v-deep .aaaa{
  width: 50%;
}
::v-deep .el-card__body {
  padding: 0;
}
::v-deep .el-main{
  overflow: auto !important;
}
::v-deep .el-card.is-always-shadow{
  width: 95%;
  margin: auto;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
::v-deep .tabulation {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  overflow: auto !important;
}
</style>
<style scoped>
::v-deep .upload-demo{
  display: flex;
  width: 100%;
}
::v-deep .upload-demo .el-upload-list{
  display: flex;
  width: 50%;
}
::v-deep .el-input{
  height: 44px !important;
}
::v-deep .el-form-item__label{
  width: 80px !important;
  font-weight: bold;
}
</style>
